<template>
  <div class="home">
    <div class="tilte_btn">
      <span class="lineY"></span>
      <span>
        <h2>职位基础信息添加</h2>
      </span>
    </div>
    <!--<div style="padding:10px;">
            <span class="ent_del">企业名称：</span>
            <el-autocomplete
                class="inline-input"
                style="width:30%"
                size="medium"
                value-key="name"
                v-model="jobinfoBasic.entname"
                :fetch-suggestions="entInputSearch"
                placeholder="请输入企业名称"
                @select="entNamehandleSelect"
            ></el-autocomplete>
        </div>-->
    <div style="padding:10px;">
      <span class="ent_del"><span style="color: red;">*</span>企业名称： </span>
      <el-input v-model="jobinfoBasic.entname" @focus="enterpriseBlur" size="medium" style="width:30%" placeholder="请选择企业"></el-input>
    </div>
    <div style="padding:10px;">
      <span class="ent_del"><span style="color: red;">*</span>职位名称： </span>
      <el-input v-model="jobinfoBasic.jobname" size="medium" style="width:30%" placeholder="请输入职位名称"></el-input>
    </div>
    <!-- <div style="padding:10px;" class="plalacss">
            <span class="ent_del">行业分类：</span>
            <el-cascader
				:class="jobinfoBasic.indtypeNames?'ph':''"
				v-model="jobinfoBasic.industryInfoShowNames"
				@change="changeIndtypes"
				:options="industryInfoSelectIds"
				:props="industryInfoProps"
				:show-all-levels="false"
				clearable
				:placeholder="jobinfoBasic.indtypeNames?jobinfoBasic.indtypeNames:'请选择行业分类'"
				size="medium"
				style="width:30%"
            ></el-cascader>
        </div> -->
    <div style="padding:10px;" class="plalacss">
      <span class="ent_del"><span style="color: red;">*</span>职位类别： </span>
      <el-cascader :class="jobinfoBasic.cartypeNames?'ph':''" v-model="jobinfoBasic.careeInfoShowNames" @change="changeEnttypes" :options="careeInfoSelectIds" :props="careeInfoProps"
        :show-all-levels="false" clearable :placeholder="jobinfoBasic.cartypeNames?jobinfoBasic.cartypeNames:'请选择行业分类'" size="medium" style="width:30%"></el-cascader>
    </div>
    <div style="padding:10px;">
      <span class="ent_del"><span style="color: red;">*</span>招聘人数： </span>
      <el-input type="number" @input="input" :min="1" v-model="jobinfoBasic.recruitNumIndex" size="medium" style="width:30%" placeholder="请输入招牌人数"></el-input>

      <!-- <el-select v-model="jobinfoBasic.recruitNumIndex" placeholder="请选择" style="width:30%">
        <el-option v-for="(item, index) in recruitArr" :key="index" :label="item.label" :value="index">
        </el-option>
      </el-select> -->
    </div>
    <div style="padding: 10px;">
      <span class="ent_del"><span style="color: red;">*</span>薪资待遇： </span>
      <el-input v-model="minSalaryText" size="medium" style="width: 120px;" type="number" min="0"></el-input>
      <span style="margin: 0 10px;">—</span>
      <el-input v-model="maxSalaryText" size="medium" style="width: 120px;" type="number" :min="minSalaryText"></el-input>
      <span style="margin: 0 12px;">元</span>
      <span>
        <el-checkbox v-model="checkedSalary" @change="salaryTab">面议</el-checkbox>
      </span>
      <!-- <el-select v-model="jobinfoBasic.salaryIndex" placeholder="请选择" style="width:30%">
                <el-option
                    v-for="(item, index) in salaryArr"
                    :key="index"
                    :label="item.label"
                    :value="index">
                </el-option>
            </el-select> -->
    </div>
    <div style="padding:10px;">
      <span class="ent_del">职位类型： </span>
      <el-radio-group @change="changeJobtype" v-model="jobinfoBasic.jobtype">
        <el-radio :label="1" v-if="userType!=1">全职</el-radio>
        <el-radio :label="2">普工/兼职</el-radio>
      </el-radio-group>
      <el-cascader v-show="isJobtype" class="el_input" v-model="jobinfoBasic.typeid" placeholder="请选择分类" @change="changeProps" :options="generalList" :props="props" :show-all-levels="true">
      </el-cascader>
    </div>
    <div style="padding: 10px;" v-show="isJobtype">
      <span class="ent_del">用工时长： </span>
      <el-radio-group v-model="jobinfoBasic.workHours">
        <el-radio :label="1">长期工</el-radio>
        <el-radio :label="2">临时工</el-radio>
        <el-radio :label="3">钟点工</el-radio>
      </el-radio-group>
    </div>
    <div style="padding: 10px;" v-show="isJobtype">
      <span class="ent_del">用工形式： </span>
      <el-radio-group v-model="jobinfoBasic.workTypes">
        <el-radio :label="1">劳务派遣工</el-radio>
        <el-radio :label="2">企业合同工</el-radio>
      </el-radio-group>
    </div>
    <div style="padding: 10px;" v-show="isJobtype">
      <span class="ent_del">安全措施： </span>
      <el-input v-model="jobinfoBasic.safeProtectStep" size="medium" style="width:30%" placeholder="请输入安全安全措施描述"></el-input>
    </div>
    <div style="padding: 10px;">
      <span class="ent_del">婚姻要求： </span>
      <el-radio-group v-model="jobinfoBasic.maritalRequire">
        <el-radio :label="-1">不限</el-radio>
        <el-radio :label="0">已婚</el-radio>
        <el-radio :label="1">未婚</el-radio>
      </el-radio-group>
    </div>
    <div style="padding: 10px;">
      <span class="ent_del">性别要求： </span>
      <el-radio-group v-model="jobinfoBasic.sexRequire">
        <el-radio :label="-1">不限</el-radio>
        <el-radio :label="0">男</el-radio>
        <el-radio :label="1">女</el-radio>
      </el-radio-group>
    </div>

    <div style="padding: 10px;">
      <span class="ent_del">结算方式： </span>
      <el-radio-group v-model="jobinfoBasic.getMoneyWay">
        <el-radio :label="1">日结</el-radio>
        <el-radio :label="2">周结</el-radio>
        <el-radio :label="3">月结</el-radio>
        <el-radio :label="4">年结</el-radio>
      </el-radio-group>
    </div>
    <div style="padding: 10px;">
      <span class="ent_del">薪资方式： </span>
      <el-radio label="时薪" v-model="jobinfoBasic.showSalaryLabel">时薪</el-radio>
      <el-radio label="日薪" v-model="jobinfoBasic.showSalaryLabel">日薪</el-radio>
      <el-radio label="周薪" v-model="jobinfoBasic.showSalaryLabel">周薪</el-radio>
      <el-radio label="月薪" v-model="jobinfoBasic.showSalaryLabel">月薪</el-radio>
      <el-radio label="年薪" v-model="jobinfoBasic.showSalaryLabel">年薪</el-radio>
    </div>
    <div style="padding:10px;">
      <span class="ent_del">学历要求： </span>
      <button @click="chengsrecordAsk(index)" v-for="(item,index) in recordAsk" :key="index" class="btn_ask" :class="recordstyle==index?'isAsk':''">
        {{item.label}}
      </button>
    </div>
    <div style="padding:10px;">
      <span class="ent_del">工作经验： </span>
      <button @click="chengsWorkAsk(index)" v-for="(item,index) in experWorkAsk" :key="index" class="btn_ask" :class="Workstyle==index?'isAsk':''">
        {{item.label}}
      </button>
    </div>
    <!-- <div style="padding:10px">
            <div class="mapBox" id="mapBox"> -->
    <!-- 地图 -->
    <!-- <div class="map_info">
                    <input id='tipinput' v-model="mapkeyword" placeholder="请输入工作地址"/>
                </div>
            </div>
        </div> -->
    <div class="addrs" style="padding:10px">
      <span class="iconfont icondizhi"></span>
      <span v-if="jobinfoBasic.wprovinceName || jobinfoBasic.wcityName || jobinfoBasic.wcountyName || jobinfoBasic.waddrDetail">
        {{jobinfoBasic.wprovinceName?jobinfoBasic.wprovinceName:''}}
        {{jobinfoBasic.wcityName?'/'+jobinfoBasic.wcityName:''}}
        {{jobinfoBasic.wcountyName?'/'+jobinfoBasic.wcountyName:''}}
        {{jobinfoBasic.waddrDetail?'/'+jobinfoBasic.waddrDetail:''}}
      </span>
      <span style="color: #999;" v-else>选择企业后自动生成详细工作地点...</span>
    </div>
    <!-- <div style="padding:10px;margin-top:10px">
            <span class="ent_del">职位亮点：</span>
            <el-tag 
                :key="index"
                v-for="(tag, index) in joblightTags"
                closable
                :disable-transitions="false"
                @close="enterprsejoblightTagsHandleClose(index)"
            >{{tag.name}}</el-tag>
			<el-button class="input-new-tag" size="small" @click="openJoblightDialog">+添加</el-button>
        </div> -->
    <div class="inte"></div>
    <div class="tilte_btn">
      <span class="lineY"></span>
      <span>
        <h2>职位描述</h2>
      </span>
    </div>
    <div style="padding:10px">
      <div style="background:#f6f7f6;padding:10px">
        <div class="ent_del"><span style="color: red;">*</span>岗位职责</div>
        <el-input type="textarea" v-model="jobinfoBasic.jobduty" placeholder="请输入简介" class="textInput" :autosize="{ minRows: 7, maxRows: 10}">
        </el-input>
      </div>
      <div style="background:#f6f7f6;padding:10px;margin-top:15px">
        <div class="ent_del"><span style="color: red;">*</span>任职要求</div>
        <el-input v-model="jobinfoBasic.takeRequire" type="textarea" placeholder="请输入简介" class="textInput" :autosize="{ minRows: 7, maxRows: 10}">
        </el-input>
      </div>
    </div>
    <div class="inte"></div>
    <div class="tilte_btn">
      <span class="lineY"></span>
      <span>
        <h2>联系方式</h2>
      </span>
    </div>
    <div style="padding:10px">
      <el-row>
        <el-col :span="8">
          <span class="ent_del"><span style="color: red;">*</span>联系人：</span>
          <el-input v-model="jobinfoBasic.contactsPerson" size="medium" style="width:80%" placeholder="请输入联系人"></el-input>
        </el-col>
        <el-col :span="8">
          <span class="ent_del"><span style="color: red;">*</span>联系方式：</span>
          <el-input v-model="jobinfoBasic.contactsNumber" size="medium" style="width:80%" placeholder="请输入联系方式"></el-input>
        </el-col>
      </el-row>
      <el-row class="m_top30">
        <el-col :span="8">
          <span class="ent_del">邮箱：</span> <!-- <span style="color: red;">*</span> -->
          <el-input v-model="jobinfoBasic.contactsEmail" size="medium" style="width:80%;margin-left:15px" placeholder="请输入邮箱"></el-input>
        </el-col>
      </el-row>
    </div>
    <div style="background:#f6f7f6;padding:20px 0">
      <el-button type="danger" @click="releaseJobData" class="btn_get">发布</el-button>
    </div>

    <!-- 职位亮点弹出框 -->
    <!-- <el-dialog title="选择该职位的亮点" :visible.sync="dialogJoblight" width="40%">
			<el-tag
				v-for="(item,index) in tagsJoblight"
				:key="index"
				class="welfareType"
				:type="item.isselect?'':'info'"
				@click="joblightDialogTagClick(index)"
				effect="dark"
            >{{ item.name }}</el-tag>
            <el-input
                style="width: 20%;margin:10px 0;"
                maxlength="10"
                v-model="tagsJoblightVal"
                size="small"
                placeholder="自定义添加"
                @blur="showJoblightInput"
            />
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogJoblight = false">取 消</el-button>
				<el-button type="primary" @click="joblightDialogOk">确 定</el-button>
			</span>
		</el-dialog> -->

    <el-dialog class="showBooth" title="选择企业" :visible.sync="showEnterprise" width="60%">
      <div class="show-booth">
        <div class="b-h">
          <div class="s-box">
            <el-input @input="handlSearch" v-model="searchParam.keywords" style="width: 65%;" prefix-icon="el-icon-search" placeholder="请输入内容"></el-input>
            <el-button @click="handlSearch" type="primary" icon="el-icon-search">搜索</el-button>
          </div>
        </div>
        <!--<div class="ck" style="color: #cc8239;">注：点击行即可选择</div>-->
        <div class="b-b" style="min-height: 400px;">
          <el-table title="点击当前行即可选择" highlight-current-row max-height="450" :data="entTableData" @current-change="handleCurrentChange($event)" style="width: 100%;margin-bottom: 20px" border>
            <el-table-column type="index" width="50" align="center"></el-table-column>
            <el-table-column prop="name" label="企业名字" width="350" align="center"></el-table-column>
            <el-table-column prop="creditCode" label="统一社会信用代码" align="center" width="300"></el-table-column>
            <el-table-column prop="legalPerson" label="法人" align="center" width="211"></el-table-column>
          </el-table>
          <div v-if="entTablePage.pageCount > 1" style="height:60px;text-align: center;display: flex;align-items: center;justify-content: center">
            <el-pagination :hide-on-single-page="true" @current-change="entcurrentChange($event)" background layout="prev, pager, next" :current-page="searchParam.page"
              :page-size="entTablePage.pageSize" :total="entTablePage.recordCount"></el-pagination>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEnterprise = false">取 消</el-button>
        <el-button type="primary" @click="showEnterprise = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import { lazyAMapApiLoaderInstance } from 'vue-amap';
export default {
  name: 'JobAdd',
  data () {
    return {
      minSalaryText: '',
      maxSalaryText: '',

      checkedSalary: false,//	是否面议
      // mapkeyword:'',//地图收索，不用了
      userType: 0,
      indTypeIds: "",
      tradeIds: "",
      jobinfoBasic: {
        jobtype: 1,
        maritalRequire: -1,
        sexRequire: -1,
        getMoneyWay: 3,
        showSalaryLabel: '月薪',
        industryInfoShowNames: [],
        recruitNumIndex:null
      },
      //普工和兼职
      isJobtype: false,
      addUpPermissions: [],
      props: {
        lazy: false,
        value: 'id',
        checkStrictly: true,
        label: 'navName'
        //,lazyLoad: this.menusfather
      },
      showEnterprise: false,
      searchParam: {
        createtime: '',
        nature: '',
        auditState: '-1',
        scaleEnt: '',
        keywords: "",
        page: 1,
        limit: 20
      },
      entTableData: [],
      entTablePage: {},
      industryInfoSelectIds: [],//行业分类
      industryInfoProps: {//行业分类多级联动
        lazy: true,
        value: "id",
        checkStrictly: true,
        label: "name",
        multiple: true,
        lazyLoad: this.industryInfoLazyLoad
      },
      careeInfoSelectIds: [],//职位分类
      careeInfoProps: {
        lazy: true,
        value: "id",
        checkStrictly: true,
        label: "name",
        multiple: true,
        lazyLoad: this.careeInfoLazyLoad
      },
      recruitArr: this.Util.recruitArr, //招聘人数数组
      // salaryArr: this.Util.salaryArr, //薪资待遇数据
      recordAsk: [],//学历要求
      recordstyle: 0,

      experWorkAsk: [],//经验要求
      Workstyle: 0,

      //职位亮点
      // tagsJoblight:[],
      // joblightTags:[],
      // tagsJoblightVal:'',
      // dialogJoblight:false,

      generalList: [],
      submitData: {}
    }
  },
  created () {
    var _self = this;
    this.userType = this.Util.getUserType();
    this.comParam = this.$router.currentRoute.query;

    if (this.comParam && !this.comParam.jobid) {
      // console.log('添加职位信息', this.comParam)
      this.jobinfoBasic.entname = this.comParam.name;
      this.jobinfoBasic.entid = this.comParam.id;
      this.jobinfoBasic.indtypeIds = this.comParam.indtypeIds;
      this.jobinfoBasic.jobLightPoint = this.comParam.welfare;//企业福利充当亮点
      this.jobinfoBasic.contactsPerson = this.comParam.contactsPerson;
      this.jobinfoBasic.contactsNumber = this.comParam.contactsNumber;
      this.jobinfoBasic.contactsEmail = this.comParam.contactsEmail;

      this.jobinfoBasic.wprovinceName = this.comParam.eprovinceName;
      this.jobinfoBasic.wcityName = this.comParam.ecityName;
      this.jobinfoBasic.wcountyName = this.comParam.ecountyName;
      this.jobinfoBasic.waddrDetail = this.comParam.eaddrDetail;

      if (this.comParam.maxSalary == -1 && this.comParam.minSalary == -1) {
        this.checkedSalary = true
      } else {
        _self.maxSalaryText = this.comParam.maxSalary;
        _self.minSalaryTest = this.comParam.minSalary;
      }
    }

    if (this.comParam.jobid) {
      _self.jobid = this.comParam.jobid;
      this.findData();
    }
    this.recordAsk = this.Util.recordAsk;
    this.experWorkAsk = this.Util.experWorkAsk;

    // lazyAMapApiLoaderInstance.load().then(() => {
    //     var point = new AMap.LngLat(116.445294,39.891345);
    //     _self.map = new AMap.Map('mapBox', {
    //         center: point,
    //         zoom: 13
    //     });
    //     var marker = new AMap.Marker({
    //         position:point ,  // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
    //     });
    //     _self.map.add(marker);
    //     _self.map.plugin('AMap.Autocomplete', function(){
    //         // 实例化Autocomplete
    //         var autoOptions = {
    //             //city 限定城市，默认全国
    //             city: '全国',
    //             input: 'tipinput'
    //         }
    //         var auto= new AMap.Autocomplete(autoOptions);
    //         var placeSearch = new AMap.PlaceSearch({
    //             map:_self.map
    //         });  //构造地点查询类
    //         AMap.event.addListener(auto, "select", select);//注册监听，当选中某条记录时会触发
    //         function select(e) {
    //             placeSearch.setCity(e.poi.adcode);
    //             placeSearch.search(e.poi.name);  //关键字查询查询
    //         }
    //     });
    //     _self.map.on('click',function (ev) {
    //         _self.map.remove(marker);
    //         marker = new AMap.Marker({
    //             position:new AMap.LngLat(ev.lnglat.lng,ev.lnglat.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
    //         });
    //         _self.finareacode(ev.lnglat.lng,ev.lnglat.lat);
    //         _self.map.add(marker);
    //     });
    // });
  },
  methods: {
    input(e){
      console.log(e,"sssssssssss")
      if(Number(e)<1){
       this.jobinfoBasic.recruitNumIndex=1
       this.$message.warning("招牌人数不可以小于1 !")
      }else{
        return
      }
    },
    changeInput (e) {
      console.log(e)
      this.$forceUpdate()
    },
    // 是否面议
    salaryTab: function (e) {
      if (e) {
        this.maxSalaryText = '';
        this.minSalaryText = '';
        this.jobinfoBasic.minSalary = -1;
        this.jobinfoBasic.maxSalary = -1;
      } else {
        this.jobinfoBasic.minSalary = '';
        this.jobinfoBasic.maxSalary = '';
      }
    },
    clone: function (Obj) {
      var _self = this;
      var buf;
      if (Obj instanceof Array) {
        buf = [];
        var i = Obj.length;
        while (i--) {
          buf[i] = _self.clone(Obj[i]);
        }
        return buf;
      }
      else if (Obj instanceof Object) {
        buf = {};
        for (var k in Obj) {
          buf[k] = _self.clone(Obj[k]);
        }
        return buf;
      } else {
        return Obj;
      }
    },
    //获取职位详情
    findData: function () {
      var _self = this;
      axios({
        url: _self.API.ajob.detail,
        headers: {
          token: _self.Util.getjwtToken(),
        },
        params: {
          id: _self.jobid,
        }
      }).then(function (e) {
        var resp = e.data;
        if (resp.success) {
          _self.jobinfoBasic = resp.data.jobinfo;
          // _self.jobinfoBasic.recruitNumIndex = 0;
          _self.$set(_self.jobinfoBasic, 'recruitNumIndex', 0)
          if (_self.jobinfoBasic.maxSalary == -1 && _self.jobinfoBasic.minSalary == -1) {
            _self.checkedSalary = true
          } else {
            _self.maxSalaryText = _self.jobinfoBasic.maxSalary;
            _self.minSalaryText = _self.jobinfoBasic.minSalary;
          }

          // submitData.recruitNumMax = _this.jobinfoBasic.recruitNumIndex;
          if (_self.jobinfoBasic.recruitNumMin > -1) {
            _self.$set(_self.jobinfoBasic, 'recruitNumIndex', _self.jobinfoBasic.recruitNumMin)
          } else if (_self.jobinfoBasic.recruitNumMax > -1) {
            _self.$set(_self.jobinfoBasic, 'recruitNumIndex', _self.jobinfoBasic.recruitNumMax)
          } else {
            _self.$set(_self.jobinfoBasic, 'recruitNumIndex', 0)
          }

          // var reg = /^\d+$/;
          // for (var i = 0; i < _self.recruitArr.length; i++) {
          //   if (typeof (_self.jobinfoBasic.recruitNumMin) != 'undefined'
          //     && _self.jobinfoBasic.recruitNumMin != null
          //     && reg.test(_self.jobinfoBasic.recruitNumMin)
          //     && typeof (_self.jobinfoBasic.recruitNumMax) != 'undefined'
          //     && _self.jobinfoBasic.recruitNumMax != null
          //     && reg.test(_self.jobinfoBasic.recruitNumMax)) {
          //     if (_self.recruitArr[i].min == parseInt(_self.jobinfoBasic.recruitNumMin) &&
          //       _self.recruitArr[i].max == parseInt(_self.jobinfoBasic.recruitNumMax)) {
          //       // _self.jobinfoBasic.recruitNumIndex = i;
          //       _self.$set(_self.jobinfoBasic, 'recruitNumIndex', i)
          //     }
          //   }
          // }
          // for(var i=0;i<_self.salaryArr.length;i++){
          //     if(_self.jobinfoBasic.minSalary && _self.jobinfoBasic.maxSalary){
          //         if(_self.salaryArr[i].min == _self.jobinfoBasic.minSalary && 
          //         _self.salaryArr[i].max == _self.jobinfoBasic.maxSalary){
          //             _self.jobinfoBasic.salaryIndex = i;
          //         }
          //     }
          // }
          for (var i = 0; i < _self.recordAsk.length; i++) {
            if (_self.recordAsk[i].value == _self.jobinfoBasic.educationRequire) {
              _self.recordstyle = i
            }
          }
          for (var i = 0; i < _self.experWorkAsk.length; i++) {
            if (_self.jobinfoBasic.workExperienceMin && _self.jobinfoBasic.workExperienceMax) {
              if (_self.experWorkAsk[i].min == _self.jobinfoBasic.workExperienceMin &&
                _self.experWorkAsk[i].max == _self.jobinfoBasic.workExperienceMax) {
                _self.Workstyle = i;
              }
            }
          }
          //亮点
          // var temp = _self.jobinfoBasic.jobLightPoint.split("-");
          // for(var i=0;i<temp.length-1;i++){
          //     _self.joblightTags.push({
          //         name:temp[i]
          //     }) 
          // }
          // _self.jobinfoBasic.indtypeIds111 = resp.data.jobinfo.indtypeIds;
          //_self.$set(_self.jobinfoBasic, 'indtypeIds', resp.data.jobinfo.indtypeIds)
          //console.log('==>', _self.jobinfoBasic.indtypeIds)
          _self.entInfo = resp.data;
          //地图,先不用了
          // _self.setMap();
        }
      })
    },
    setMap: function () {
      var _self = this;
      if (_self.entInfo.enterprise.entLng > 0
        && _self.entInfo.enterprise.entLat > 0) {
        lazyAMapApiLoaderInstance.load().then(() => {
          var point = new AMap.LngLat(_self.entInfo.enterprise.entLng, _self.entInfo.enterprise.entLat);
          _self.map = new AMap.Map('mapBox', {
            center: point,
            zoom: 13
          });
        });
      }
    },
    //普工兼职
    changeJobtype: function (val) {
      if (val == 2) {
        this.getGeneralList();
        this.isJobtype = true;
      } else {
        this.isJobtype = false;
      }
    },
    changeProps: function (arrid) {
      var _self = this;
      if (arrid.length < 2) {
        this.$message.warning("至少选到两级哦！");
      } else {
        this.jobinfoBasic.menupid = arrid[arrid.length - 1];//子
        this.jobinfoBasic.menucid = arrid[arrid.length - 2]//父
        this.generalList.forEach(function (item) {
          if (item.id == arrid[arrid.length - 2]) {
            if (item.navName == '长期工') {
              _self.jobinfoBasic.workHours = 1;
            } else if (item.navName == '临时工') {
              _self.jobinfoBasic.workHours = 2;
            } else if (item.navName == '钟点工') {
              _self.jobinfoBasic.workHours = 3;
            }
          }
        });
      }
    },
    //根据父id获取类型菜单
    menusfather: function (node, resolve) {
      var _self = this;
      var pid = '5e318e07826741c9929386b150274ec9';
      if (node.data) {
        pid = node.data.id
      }
      axios.get(_self.API.navmenu.list, {
        headers: {
          token: _self.Util.getjwtToken(),
        },
        params: {
          type: -1,
          pid: pid
        }
      }).then(function (pores) {
        if (pores.data.success) {
          if (pores.data.data && pores.data.data.length > 0) {
            resolve(pores.data.data)
          } else {
            resolve([])
          }
        } else {
          resolve([])
        }
      });
    },
    //根据父id获取类型菜单
    getGeneralList: function () {
      var _self = this;
      axios.get(_self.API.navmenu.general_list, {
        headers: {
          token: _self.Util.getjwtToken(),
        },
        params: {}
      }).then(function (res) {
        if (res.data.success) {
          if (res.data.data && res.data.data.length > 0) {
            _self.generalList = res.data.data;
          }
        }
      });
    },
    loadEntTableData: function () {
      var _self = this;
      axios({
        url: _self.API.aent.entDblist,
        method: 'get',
        headers: {
          token: _self.Util.getjwtToken()
        },
        params: _self.searchParam
      }).then(function (e) {
        var resp = e.data;
        if (resp.success) {
          if (resp.data.list && resp.data.pager) {
            _self.entTableData = resp.data.list;
            _self.entTablePage = resp.data.pager;
          }
        }
      })
    },
    handleCurrentChange: function (item) {
      if (item) {
        this.jobinfoBasic.entname = item.name;
        this.jobinfoBasic.entid = item.id;
        this.jobinfoBasic.indtypeIds = item.indtypeIds;
        this.jobinfoBasic.jobLightPoint = item.welfare;//企业福利充当亮点
        this.jobinfoBasic.contactsPerson = item.contactsPerson;
        this.jobinfoBasic.contactsNumber = item.contactsNumber;
        this.jobinfoBasic.contactsEmail = item.contactsEmail;

        this.jobinfoBasic.wprovinceName = item.eprovinceName;
        this.jobinfoBasic.wcityName = item.ecityName;
        this.jobinfoBasic.wcountyName = item.ecountyName;
        this.jobinfoBasic.waddrDetail = item.eaddrDetail;
      }
    },
    enterpriseBlur: function () {
      this.showEnterprise = true;
      this.loadEntTableData();
    },
    handlSearch: function () {
      this.loadEntTableData();
    },
    //企业输入建议
    // entInputSearch:function(queryString, cb) {
    //     var _self=this;
    //     if(!queryString){
    //         queryString = "";
    //     }
    //     axios({
    //         url: _self.API.aent.entDblist,
    //         method: 'get',
    //         headers: {
    //             token: _self.Util.getjwtToken()
    //         },
    //         params: {
    //             createtime: '',
    //             nature:'',
    //             auditState:'-1',
    //             scaleEnt:'',
    //             keywords: queryString,
    //             page:1,
    //             limit:10
    //         }
    //     }).then(function(e) {
    //         var resp = e.data;
    //         if (resp.success) {
    //             if(resp.data.list){
    //                 cb(resp.data.list)
    //             }else{
    //                 cb([]);
    //             }
    //         }else{
    //             cb([]);
    //         }
    //     }).catch(function(e){
    //         cb([]);
    //     })
    // },
    entNamehandleSelect: function (item) {
      this.jobinfoBasic.entname = item.name;
      this.jobinfoBasic.entid = item.id;
      this.jobinfoBasic.contactsPerson = item.contactsPerson;
      this.jobinfoBasic.contactsNumber = item.contactsNumber;
      this.jobinfoBasic.contactsEmail = item.contactsEmail;
    },
    //行业分类懒加载
    industryInfoLazyLoad: function (node, resolve) {
      var eid = "0";
      var _self = this;
      if (node.data) {
        eid = node.data.id;
      }
      axios.get(_self.API.industry.findByPid, {
        headers: {
          token: _self.Util.getjwtToken()
        },
        params: {
          id: eid
        }
      }).then(function (pores) {
        if (pores.data.success) {
          if (pores.data.data && pores.data.data.length > 0) {
            resolve(pores.data.data);
          } else {
            resolve([]);
          }
        }
      })
    },
    //职位分类懒加载
    careeInfoLazyLoad: function (node, resolve) {
      var eid = "0";
      var _self = this;
      if (node.data) {
        eid = node.data.id;
      }
      axios.get(_self.API.jtAll.findByPid, {
        headers: {
          token: _self.Util.getjwtToken()
        },
        params: {
          id: eid
        }
      }).then(function (pores) {
        if (pores.data.success) {
          if (pores.data.data && pores.data.data.length > 0) {
            resolve(pores.data.data);
          } else {
            resolve([]);
          }
        }
      })
    },
    //选择行业分类id
    changeIndtypes: function (arrid) {
      var indtypeIds = "";
      if (arrid && arrid.length > 0) {
        indtypeIds = arrid[0][arrid[0].length - 1]
        for (var i = 1; i < arrid.length; i++) {
          indtypeIds += "-" + arrid[i][arrid[i].length - 1]
        }
      }
      if (indtypeIds != "") {
        this.jobinfoBasic.indtypeIds = indtypeIds;
      }
    },
    //选择职位分类id
    changeEnttypes: function (arrid) {
      var cartypeIds = "";
      if (arrid && arrid.length > 0) {
        cartypeIds = arrid[0][arrid[0].length - 1]
        for (var i = 1; i < arrid.length; i++) {
          cartypeIds += "-" + arrid[i][arrid[i].length - 1]
        }
      }
      this.jobinfoBasic.cartypeIds = cartypeIds;
    },
    //根据经纬度获取地址
    finareacode: function (lng, lat) {
      var _self = this;
      axios.get(_self.API.areacode.getAddrByLnglat, {
        headers: {
          token: _self.Util.getjwtToken()
        },
        params: {
          lng: lng,
          lat: lat
        }
      }).then(function (pores) {
        if (pores.data.success) {
          _self.$set(_self.jobinfoBasic, 'wprovinceName', pores.data.data.provinceName)
          _self.jobinfoBasic.wprovinceCode = pores.data.data.provinceCode;

          _self.$set(_self.jobinfoBasic, 'wcityName', pores.data.data.cityName);
          _self.jobinfoBasic.wcityCode = pores.data.data.cityCode;

          _self.$set(_self.jobinfoBasic, 'wcountyName', pores.data.data.countyName);
          _self.jobinfoBasic.wcountyCode = pores.data.data.countyCode;
          _self.jobinfoBasic.waddrDetail = pores.data.data.addrDetail;
          //经纬度
          _self.jobinfoBasic.addrlng = lng;
          _self.jobinfoBasic.addrlat = lat;
        }
      })
    },
    //学历要求选择
    chengsrecordAsk: function (index) {
      this.recordstyle = index;
      this.jobinfoBasic.educationRequire = this.recordAsk[index].value;
    },
    //经验要求
    chengsWorkAsk: function (index) {
      this.Workstyle = index;
      this.jobinfoBasic.workExperienceMax = this.experWorkAsk[index].max;
      this.jobinfoBasic.workExperienceMin = this.experWorkAsk[index].min;
    },
    //亮点
    //       enterprsejoblightTagsHandleClose(index) {
    // 	this.joblightTags.splice(index, 1);
    // },
    openJoblightDialog: function () {
      var _this = this;
      axios.get(this.API.baseJoblight.list, {
        headers: {
          token: this.Util.getjwtToken()
        }
      }).then(function (res) {
        if (res.data.success) {
          var joblightList = res.data.data;
          for (var i = 0; i < joblightList.length; i++) {
            if (_this.joblightTags && _this.joblightTags.length > 0) {
              for (var j = 0, len = _this.joblightTags.length; j < len; j++) {
                if (_this.joblightTags[j].id == joblightList[i].id) {
                  joblightList[i].isselect = true;
                }
              }
            }
            joblightList[i].typeClass = true;
          }
          _this.tagsJoblight = joblightList;
          _this.dialogJoblight = true;
        } else {
          _this.$message.error("获取失败");
        }
      })
    },
    joblightDialogTagClick: function (index) {
      var item = this.tagsJoblight[index];
      item.isselect = !item.isselect;
      this.$set(this.tagsJoblight, index, item);
    },
    showJoblightInput: function () {
      if (this.tagsJoblightVal) {
        this.tagsJoblight.push({
          name: this.tagsJoblightVal,
          isselect: false,
        })
      }
    },
    joblightDialogOk: function () {
      var tags = [];
      for (var i = 0; i < this.tagsJoblight.length; i++) {
        if (this.tagsJoblight[i].isselect) {
          tags.push(this.tagsJoblight[i]);
        }
      }
      this.dialogJoblight = false;
      this.joblightTags = tags;
    },
    //提交发布
    releaseJobData: function () {
      var submitData = JSON.parse(JSON.stringify(this.jobinfoBasic));
      var _this = this;
      submitData.adduid = _this.Util.getUid();//取登入的id
      submitData.jobsource = 1; //来源 1 后台管理员录入 2 公司自己添加
      submitData.jobtype = 1; //职位类型 1全职 2普工/兼职
      submitData.recruitType = 1; //招聘中
      if (!submitData.entname) {
        _this.$message.error('企业名称不能为空');
        return;
      }
      if (!submitData.jobname) {
        _this.$message.error('岗位名称不能为空');
        return;
      }
      if (submitData.indtypeIds == "") {
        _this.$message.error('请选择行业');
        return;
      }
      if (!submitData.cartypeIds) {
        _this.$message.error('请选择职业');
        return;
      }

      if (submitData.recruitNumIndex || submitData.recruitNumIndex == 0) {
        submitData.recruitNumMin = _this.jobinfoBasic.recruitNumIndex;
        submitData.recruitNumMax = _this.jobinfoBasic.recruitNumIndex;
        // submitData.recruitNumMin = _this.recruitArr[submitData.recruitNumIndex].min;
        // submitData.recruitNumMax = _this.recruitArr[submitData.recruitNumIndex].max;
      } else {
        _this.$message.error('请选择招聘人数');
        return;
      }
      if (!this.checkedSalary) {
        if (!this.minSalaryText) {
          _this.$message.error('请输入最小薪资待遇');
          return
        }
        if (!this.maxSalaryText) {
          _this.$message.error('请输入最大薪资待遇');
          return
        }
        if (this.minSalaryText > this.maxSalaryText) {
          _this.$message.error('最小新资不能大于最大薪资');
          return
        }
        submitData.minSalary = this.minSalaryText;
        submitData.maxSalary = this.maxSalaryText;
      } else {
        if (this.minSalaryText || this.maxSalaryText) {
          _this.$message.error('您勾选了面议又填写了薪资');
          return
        }
      }
      if (this.isJobtype && !this.jobinfoBasic.typeid) {
        _this.$message.error('请选择普工/兼职分类');
        return;
      }
      //  if(_this.joblightTags && _this.joblightTags.length > 0){
      //      submitData.jobLightPoint = "";
      //      for(var i = 0, len = _this.joblightTags.length; i < len; i ++){
      //          if(i > 0){
      //              submitData.jobLightPoint += "-";
      //          }
      //          submitData.jobLightPoint += _this.joblightTags[i].name;
      //      }
      //  }else{
      //       _this.$message.error('请选择职位亮点');
      //      return;
      //  }
      if (!submitData.jobduty) {
        _this.$message.error('请输入岗位职责')
        return
      }
      if (!submitData.takeRequire) {
        _this.$message.error('请输入任职要求')
        return
      }
      submitData.getMoneyWay = 3; //默认月结吧
      submitData.showSalaryLabel = "月";
      submitData.audit = 2;
      const loading = this.$loading({
        lock: true,
        text: '保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      console.log(submitData,"submitData")
      axios({
        url: _this.API.jobinfo.insert,
        method: "post",
        data: submitData,
        headers: {
          token: this.Util.getjwtToken()
        }
      }).then(function (e) {
        var resp = e.data;
        if (resp.success) {
          _this.$message({
            message: resp.msg,
            type: "success",
            duration: 2000,
            onClose: function () {
              _this.$parent.removeTab(_this.$parent.editableTabsValue)
            }
          });
          loading.close();
        } else {
          loading.close();
          _this.$message.error(resp.msg);
        }
      }).catch(function () {
        loading.close();
      })

    }
    , entcurrentChange (n) {
      this.searchParam.page = n;
      this.loadEntTableData();
    }
  }

}
</script>
<style lang="scss" scoped>
.tilte_btn {
  background-color: #ffffff;
}
.btn_ask {
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
}
.btn_ask:nth-child(n + 3) {
  margin-left: 15px;
}
.isAsk {
  color: #ffffff;
  background-color: #409eff;
}
.inte {
  height: 15px;
}
.welfareType {
  cursor: pointer;
}
.textInput {
  width: 100%;
  margin-top: 10px;
  background: none !important;
}
.addrs {
  padding: 5px 20px;
  font-size: 17px;
  .iconfont {
    margin-right: 10px;
  }
}

.showBooth {
  /deep/.el-dialog__body {
    padding: 0 20px;

    .current-row {
      color: #03a9f4;
      font-weight: bold;
    }

    tbody > tr {
      cursor: pointer;
    }
  }

  .show-booth {
    .b-h {
      display: flex;
      margin-bottom: 20px;
      .s-box {
        margin-left: 15px;
        button {
          margin-left: 15px;
        }
      }
    }
  }
}
</style>